import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout.en";
import SEO from "../components/seo";
import style from "./avisos.module.scss"
import imgPage from "../images/headers/Logos_Ale.svg"
import Header from "../components/header";
import Img from "gatsby-image"

export default ({data}) => {

    return (
        <Layout footer='default'>

            <SEO title="Aviso de Privacidad"/>
            <Header LogoPage={imgPage}/>

            <div className={style.avisosPortada}>
                <Img style={{maxHeight: '400px'}} fluid={data.portada.childImageSharp.fluid}/>
            </div>

            <div className={style.avisosContent}>
                <h2>PRIVACY NOTICE</h2>
                <p>

                    <b>I. RESPONSIBLE FOR THE PROTECTION OF PERSONAL DATA</b> <br/> <br/>
                    Aerolíneas Ejecutivas is a Variable Stock Corporation domiciled in Aeropuerto Internacional de
                    Toluca, Ex. Hacienda Canalejas, Calle 2 Lotes 14-18, C.P. 50200, Toluca, Estado de México and
                    telephone (722) 279-1600, as Responsible for the protection of your Personal Data it has designated
                    for this purpose the "Committee for the Protection of Personal Data" in compliance with the
                    provisions of the Federal Law of Protection of Personal Data in Possession of Private Individuals,
                    Aerolíneas Ejecutivas, S.A. de C.V. (hereinafter "ALE") hereby informs you the following guidelines
                    on this Privacy Notice: <br/> <br/>
                    <b>II. PROCESSING AND PURPOSE OF PERSONAL DATA</b> <br/> <br/>
                    The personal data of ALE’s customers, of whom ALE has access for the contractual or commercial
                    relationship, are kept as private. In turn, ALE only requests the information it needs, i.e.
                    exclusively the information required for the provision of services to its customers, also for
                    recording of certain promotional activities or in order to send quotes, information or products to a
                    recipient other than the customer; in such cases, the information of the final recipient will also
                    be obtained by e-mail, or other means of communication that ALE usually uses such as telephone,
                    email, fax, scanner and/or any other means of communication referred to in this site. <br/><br/>
                    <b>III. PERSONAL DATA WE COLLECT</b> <br/> <br/>
                    Among the information requested from the customer for purposes of the contractual or commercial
                    relationship and that ALE collects are: <br/>
                    a) Full name <br/>
                    b) Email <br/>
                    c) Company where the customer works <br/>
                    d) Address: Street and number, Neighborhood, Political Delegation or municipality, State, City and
                    Zip Code <br/>
                    e) Telephone <br/>
                    f) Fax <br/>
                    In addition to the above data, when the customer makes a service request, the following personal
                    data is collected: <br/>
                    a) Taxpayer’s Registry Number (RFC) <br/>
                    b) Account number <br/>
                    c) Bank <br/>
                    d) Name of Account Holder <br/>

                    <b>IV. MEANS TO LIMIT THE USE OR DISCLOSURE OF PERSONAL DATA</b> <br/> <br/>
                    ALE has physical, technical and administrative security measures for the protection of Personal Data
                    provided by users. <br/> <br/>
                    <b>V. TRANSFER OF PERSONAL DATA</b> <br/><br/>
                    ALE may, release, disclose and/or transfer within and outside the country the Personal Data provided
                    by users, without the owner's consent, to its affiliates, subsidiaries and/or related companies, as
                    well as to comply with legal provisions that so require, or when it is so requested by competent
                    authorities. <br/> <br/>
                    <b>VI. USE OF INFORMATION</b> <br/> <br/>
                    ALE collects statistical information that allows us to track the frequency of visits to our website
                    and its sections and such information is securely stored on a server that does not have internet
                    access.
                    <br/>
                    ALE does not rent or sell personal data to third parties; however, it may use the collected
                    information for statistical and marketing purposes, i.e. information about the provision of a
                    service, therefore, it does not infringe the Federal Law of Protection of Personal Data in
                    Possession of Private Individuals. <br/> <br/>
                    <b>VII. SITE CONTENT</b> <br/> <br/>
                    The content of this site is intended to provide its visitors with the ease of knowing in a general
                    manner (and as approximately as possible) the services provided by ALE, but this does not imply or
                    warrant that the information contained herein may satisfy the requirements of precision, detail,
                    accuracy or veracity required by its visitors, therefore, if the customer wishes to obtain accurate
                    and specific information (including quotes, contracts, requests, and/or services), he may make any
                    inquiries that he deems relevant through these means or those expressly indicated on this
                    site. <br/>
                    The information contained on our site, unless otherwise expressly mentioned, shall not be considered
                    in any way as the realization of a contracting offer made to the customer. Any comments,
                    suggestions, proposals, studies, offers or in general any type of information that the customer
                    sends through emails and/or telephones and/or faxes and/or any other means of communication referred
                    to in this site, will not imply the acceptance of ALE of any kind of commitment or obligation to the
                    sending customer. <br/>
                    The site may contain hyperlinks, buttons and/or web search tools, which, when used by users,
                    transport to other Internet sites that may be owned by third parties. The Personal Data that users
                    provide through these portals or Internet sites are not contemplated by this Notice and their
                    processing is not the responsibility of ALE.
                    <br/> <br/>
                    <b>VIII. ACCESS, RECTIFICATION, CANCELLATION OR OPPOSITION RIGHTS</b> <br/> <br/>
                    ALE has a database subject to the provisions of the Federal Law of Protection of Personal Data in
                    Possession of Private Individuals, so, in accordance with the provisions of this privacy notice, at
                    the time you make a query or establish a commercial or contractual relationship, ALE requests your
                    authorization for the use of your personal data and makes available to you, for any request for
                    access, rectification, cancellation or opposition (hereinafter "ARCO Rights") related to your
                    personal data this procedure to request any type of information related to the processing of your
                    personal data or the exercise of any of the ARCO rights recognized by the aforementioned Law,
                    without limiting your right to do so personally in our offices. <br/>
                    Procedure: <br/>
                    i. The Owner of the Personal Data or its legal representative will be the only ones empowered to
                    request the exercise of its ARCO rights to ALE who is responsible for protecting its Personal
                    Data. <br/>
                    ii. The receipt of the application to exercise ARCO Rights, may be through: <br/>
                    a) www.aerolineasejecutivas.com web Site <br/>
                    b) Email addressed to the Committee for the Protection of Personal Data at the following address:
                    atenciondatos@aerolineasejecutivas.com <br/>
                    c) Written notice delivered directly to our offices located at: <br/>
                    Aeropuerto Internacional de Toluca, Ex. Hacienda Canalejas, Calle 2 Lotes 14-18, C.P. 50200, Toluca,
                    Estado de México. <br/>
                    iii. ALE makes available to you the following format attached to this Privacy Notice (hereinafter
                    "Application for ARCO Rights") so we ask you to attach to such document the accreditation of your
                    identity of applicant or legal representative, as applicable. <br/>
                    iv. The Committee for the Protection of Personal Data will respond to your request within a maximum
                    period of 20 (twenty) days, starting from the date on which your Application for ARCO Rights was
                    received, so that, if appropriate, it will be effective within 15 (fifteen) days from the date on
                    which the response is communicated to you.
                    <br/> <br/>
                    <b>IX. MODIFICATIONS TO THIS PRIVACY NOTICE</b> <br/> <br/>
                    We reserve the right at any time to make modifications or updates to this privacy notice, for the
                    addressing of legislative or jurisprudential developments, internal policies, new requirements for
                    the provision or offering of our services or products and market practices. These modifications will
                    be made available to the public through the following means:<br/>
                    a) Visible notices in our facilities. <br/>
                    b) On our website www.aerolineasejecutivas.com <br/>
                    c) In the email you have provided us. <br/>
                    Dear customer, you are notified that this Privacy Notice has been modified on April 20th, 2020.
                    <b>X. PROVISIONS</b> <br/> <br/>
                    In accordance with the Federal Law of Protection of Personal Data in Possession of Private
                    Individuals, your information is subject to the provisions of that law, as well as the other federal
                    and local provisions, therefore, at the request of judicial or administrative authorities in the
                    relevant area, ALE will dispose your information as ruled by the relevant authorities. <br/>
                </p>
            </div>

        </Layout>
    )

}

export const query = graphql`
    query ImagesAvisoEn {
    portada: file(relativePath: {eq: "avisos/avisodeprivacidad.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

}`

